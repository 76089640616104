import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';
import ExifOrientationImg from 'react-exif-orientation-img';
import CustomLoading from '../../../util_components/custom_loading';
import CommonHeader from '../../../util_components/common_header';
import Button from '../../../util_components/button';
import EditTeacherProfile from '../edit_teacher_profile';
import HighlightSearch from '../../../util_components/highlight_search_text';
import UploadImage from '../../../util_components/upload_image';
import CustomSingleTable from '../../../util_components/custom_single_table';
import { post_api, put_api } from '../../../../redux/api_funcs';
import CustomModal from '../../../util_components/custom_modal';
import CustomSingleSelect from '../../../util_components/custom_single_select';
import SingleAvailability from '../single_teacher_availability';
import CustomInput from '../../../util_components/custom_input';
import ApproveUpdates from '../approve_updates';
import * as actions from '../../../../redux/action_creators';
import { teacher_profile_format } from '../../../../utils/formats';
import { image_error_replace, compare_teacher_details } from '../../../../util_functions';
import { get_domain, get_api_url } from '../../../../utils/urls';
import './teacher_profile.css';
import RenderAllTags from '../../content/components/render_tags';

import { openChatWithUserUUID } from '../../../chat/chat';
import { teacher_reco_scores } from '../../../../constants';
import AccountHandler from '../../../../../myt_chat/src/chat/network/AccountHandler';
import UserRole from '../../../../../myt_chat/src/chat/enums/UserRole';

class TeacherProfile extends Component {
    state = {
        loading: true,
        teacherDetails: '',
        read_more: false,
        edit_profile: false,
        open_availability: false,
        page_error: false,
        open_approval_page: false,
        teacher_compare_details: {},
        add_story_open: false,
        story_link: '',
        story_title: '',
        story_image: '',
        add_story_loading: false,
        show_raw_data: false,
        raw_data_search: '',
        ban_open: false,
        ban_reason: '',
        unban_open: false,
        add_image_open: false,
        gallery: [],
        association_type: 'TEACHER',
        add_boost_score: false,
        boosted_score: '',
        manual_boost_type: '',
        manual_boost_confirmation: false,
        show_qa_score: false,
        altered_qa_score: 0
    };

    componentDidMount() {
        const urlString = window.location.href;
        const url = new URL(urlString);
        const uuid = url.searchParams.get('id');

        if (!uuid) {
            this.setState({ page_error: true });
        } else if (uuid === this.props.teacher_profile_uuid) {
            const teacher_compare_details = compare_teacher_details(this.props.teacher_profile_details);
            this.setState({
                teacherDetails: this.props.teacher_profile_details,
                loading: false,
                teacher_compare_details: { ...teacher_compare_details },
                gallery: this.props.teacher_profile_details?.gallery,
                uuid
            });
        } else {
            this.setState({ uuid }, () => {
                this.props.get_teacher_profile_details(uuid);
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.teacher_profile_status === 'loading' &&
            this.props.teacher_profile_status === 'success'
        ) {
            const teacher_compare_details = compare_teacher_details(this.props.teacher_profile_details);
            this.setState({
                teacherDetails: this.props.teacher_profile_details,
                loading: false,
                teacher_compare_details: { ...teacher_compare_details },
                gallery: this.props.teacher_profile_details?.gallery,
                altered_qa_score: this.props.teacher_profile_details?.altered_avg_qa_scores
            });
        }
    }

    toggle_read_more = () => {
        this.setState({ read_more: !this.state.read_more });
    };

    get_date_name = (date) => {
        const months = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
        ];
        const month_name = months[date.getMonth()];
        const date_value = date.getDate();
        const year = date.getFullYear();
        return `${month_name} ${date_value} ${year}`;
    };

    render_intro = () => {
        const intro = this.state.teacherDetails.intro_to_students;
        if (intro.length > 200) {
            if (this.state.read_more) {
                return (
                    <p className="teachers-intro-text">
                        {intro}
                        <span onClick={this.toggle_read_more}>{'   '} read less</span>
                    </p>
                );
            }
            return (
                <p className="teachers-intro-text">
                    {intro.slice(0, 200)}
                    <span onClick={this.toggle_read_more}>... read more</span>
                </p>
            );
        }
        return <p>{intro}</p>;
    };

    render_stories = () => {
        const details = this.state.teacherDetails;
        const { stories } = details;
        const stories_length = Math.floor(stories.length / 2) + 1;
        const story_array = Array.from(Array(stories_length).keys());
        return (
            <div>
                <h4>Teacher Stories:</h4>
                {story_array.map((i) => {
                    const story_1 = stories[2 * i];
                    const story_2 = stories[2 * i + 1];
                    return (
                        <div className="teacher-stories">
                            {story_1 ? (
                                <a href={story_1.url} target="_blank" rel="noopener noreferrer">
                                    <img src={story_1.image} />
                                    <p>{story_1.title}</p>
                                </a>
                            ) : null}
                            {story_2 ? (
                                <a href={story_2.url} target="_blank" rel="noopener noreferrer">
                                    <img src={story_2.image} />
                                    <p>{story_2.title}</p>
                                </a>
                            ) : null}
                        </div>
                    );
                })}
            </div>
        );
    };

    render_association_content = () => {
        const { all_tags } = this.state;
        const associated_tags = [];
        if (!!all_tags && all_tags.length) {
            for (const tag_category of all_tags) {
                for (const tag of tag_category.tags) {
                    if (tag.is_tagged == 1) {
                        associated_tags.push(tag);
                    }
                }
            }
        }

        const cont_style = 'content-width-full';

        return (
            <div className={`content-border category-template-div ${cont_style}`}>
                <div className="inner-div">
                    {!!associated_tags && associated_tags.length !== 0 ? (
                        associated_tags.map((tag) => (
                            <div className="md-chips" key={tag?.tag_uuid}>
                                <label htmlFor={tag?.uuid} id={tag?.uuid} className="md-chip">
                                    <span> {tag?.name} </span>
                                </label>
                            </div>
                        ))
                    ) : (
                        <p>
                            <strong>No tags present</strong>
                        </p>
                    )}
                </div>
            </div>
        );
    };

    render_gallery = () => {
        const { gallery } = this.state;
        return (
            <div className="teacher-gallery">
                <div className="teacher-gallery-header">
                    Teacher Gallery:
                    <button onClick={this.toggle_upload_image}>Add Image</button>
                </div>
                <div className="teacher-gallery-photos">
                    {gallery.map((url, i) => (
                        <div className="teacher-gallery-photos-single" key={i}>
                            <img src={url} />
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    toggle_upload_image = () =>
        this.setState({ image_url: '', add_image_open: !this.state.add_image_open });

    toggle_qa_score = () => this.setState({ show_qa_score: !this.state.show_qa_score });

    render_qa_score_details = (details) => (
        <div className="teacher-gallery">
            <div className="teacher-gallery-header">QA Score Details:</div>
            <table
                className="user-profile-table"
                style={{
                    width: '100%',
                    margin: '25px 0',
                    marginBottom: '20px',
                    border: '1px solid',
                    borderRadius: '5px'
                }}
            >
                <tr>
                    <td className="teacher-table-detail">Average QA Score</td>
                    <td>{details?.avg_qa_scores || ''}</td>
                    <td className="teacher-table-detail">No of Session</td>
                    <td>{details?.qa_score_no_of_session || ''}</td>
                </tr>
                <tr>
                    <td className="teacher-table-detail">Altered Average QA Score</td>
                    <td>{details?.altered_avg_qa_scores || ''}</td>
                    <td>
                        <button onClick={this.toggle_qa_score}>Edit Altered QA Score</button>
                    </td>
                </tr>
            </table>
        </div>
    );

    render_details = () => {
        const details = this.state.teacherDetails;
        const lead_time = `${details.booking_lead_in_hours} h: ${details.booking_lead_in_minutes} m`;
        const teacherDetails = {
            ...details,
            con_url: `https://myyogateacher.com/consult/${details.slug}`,
            lead_time
        };
        return (
            <>
                <CustomSingleTable data={teacherDetails} data_format={teacher_profile_format} />
                {this.render_qa_score_details(details)}
                <table
                    className="user-profile-table"
                    style={{
                        width: '100%',
                        margin: '50px 0',
                        marginBottom: '20px',
                        border: '1px solid',
                        borderRadius: '10px'
                    }}
                >
                    <tbody>
                        <tr>
                            <td className="teacher-table-detail">Yoga types</td>
                            <td>
                                {details.yoga_types_teaching
                                    ? details.yoga_types_teaching.map((yoga) => yoga.name).join(',  ')
                                    : ''}
                            </td>
                        </tr>
                        <tr>
                            <td className="teacher-table-detail">Goals</td>
                            <td>{details.goals.join(',  ')}</td>
                        </tr>
                        <tr>
                            <td className="teacher-table-detail">Schools</td>
                            <td>{details.yoga_schools.join(',  ')}</td>
                        </tr>
                        <tr>
                            <td className="teacher-table-detail">Adjectives</td>
                            <td>{details.teacher_adjectives.join(',  ')}</td>
                        </tr>
                        <tr>
                            <td className="teacher-table-detail">Certificates</td>
                            <td>
                                <ol>
                                    {details.certificates.map((certificate) => (
                                        <li key={certificate.name + certificate.start_date}>{certificate.name}</li>
                                    ))}
                                </ol>
                            </td>
                        </tr>
                        <tr>
                            <td className="teacher-table-detail">Stories</td>
                            <td>
                                {details.stories.map((story) => (
                                    <div key={story.title} className="teacher-story-con">
                                        <div className="teacher-story-img">
                                            <img src={story.image} />
                                        </div>
                                        <div className="teacher-story-content">
                                            <div>{story.title}</div>
                                            <div className="teacher-story-link">
                                                <a href={story.url} target="_blank" rel="noopener noreferrer">
                                                    {story.url}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div>
                    <h4>Teacher Video:</h4>
                    {details.video_url ? <ReactPlayer url={details.video_url} controls width="100%" /> : null}
                </div>
                <div className="teacher-video-block">
                    <h4>Teacher Video Thumbnail:</h4>
                    {details.video_thumbnail ? <img src={details.video_thumbnail} /> : null}
                </div>
                <br />
                {this.state.teacherDetails.uuid ? (
                    <RenderAllTags
                        association_type={this.state.association_type}
                        association_id={this.state.teacherDetails.uuid}
                        entity_name={this.state.teacherDetails.first_name}
                    />
                ) : null}
                {this.render_gallery()}
            </>
        );
    };

    openChatScreen = () => {
        openChatWithUserUUID(this.state.teacherDetails.uuid, UserRole.TEACHER);
    };

    openManualBoost = () => this.setState({ add_boost_score: true });

    closeManualBoost = () =>
        this.setState({ add_boost_score: false, manual_boost_confirmation: false });

    closeBoostConfirmation = () => this.setState({ manual_boost_confirmation: false });

    handleFileChange = (e) => {
        this.props.update_teacher_files(
            this.state.teacherDetails.uuid,
            e.target.name,
            e.target.files[0]
        );
    };

    update_pic = () => {
        const realInput = document.getElementById('pic-input');
        realInput.click();
    };

    update_resume = () => {
        const realInput = document.getElementById('resume-input');
        realInput.click();
    };

    update_intro = () => {
        const realInput = document.getElementById('video-input');
        realInput.click();
    };

    impersonate_teacher = () => {
        this.props.impersonate_user(this.state.teacherDetails.uuid, 'TEACHER');
    };

    close_teacher_profile = () => {
        this.props.history.goBack();
    };

    open_availability_graph = () => this.setState({ open_availability: true });

    close_availability_graph = () => this.setState({ open_availability: false });

    open_edit = () => this.setState({ edit_profile: true });

    close_edit = () => {
        this.setState({ edit_profile: false });
        this.props.get_teacher_profile_details(this.state.uuid);
    };

    open_approve_updates = () => this.setState({ open_approval_page: true });

    close_approve_updates = () => this.setState({ open_approval_page: false });

    open_teacher_sessions = (e) => {
        const url = '/usersessions/future';
        const search = `?id=${this.state.teacherDetails.uuid}&user_type=TEACHER&name=${this.state.teacherDetails.first_name} ${this.state.teacherDetails.last_name}`;
        this.props.history.push({
            pathname: url,
            search
        });
    };

    open_teacher_transactions = () => {
        const url = '/teachertransactions';
        const search = `?id=${this.state.teacherDetails.uuid}&name=${this.state.teacherDetails.first_name} ${this.state.teacherDetails.last_name}`;
        this.props.history.push({
            pathname: url,
            search
        });
    };

    reload_details = () => {
        this.props.get_teacher_profile_details(this.state.teacherDetails.uuid);
    };

    handle_modal_change = (e) => this.setState({ [e.target.name]: e.target.value });

    add_story = () =>
        this.setState({ add_story_loading: true }, () => {
            const { uuid, story_link, story_title, story_image } = this.state;
            const url = get_api_url('add_teacher_story');
            const payload = {
                teacher_uuid: uuid,
                link: story_link
            };
            post_api(url, payload, true)
                .then(() => {
                    this.props.set_notification_variable(true, 'success', 'Story added');
                    this.setState({ add_story_loading: false, add_story_open: false, story_link: '' }, () =>
                        this.props.get_teacher_profile_details(uuid)
                    );
                })
                .catch((e) => {
                    console.log(e);
                    this.props.set_notification_variable(true, 'error', 'Error occured while adding story');
                    this.setState({ add_story_loading: false });
                });
        });

    openNotificationPreferences = () => {
        const { uuid, first_name } = this.state?.teacherDetails;
        const url = '/teacher/notification_preferences';
        const search = `?id=${uuid}&name=${first_name}`;
        this.props.history.push({
            pathname: url,
            search
        });
    };

    render_add_story = () => {
        const { story_link, story_title, story_image } = this.state;
        return (
            <div>
                <h3>Add story</h3>
                <CustomInput
                    label="Enter story link"
                    style={{ width: '500px' }}
                    value={story_link}
                    name="story_link"
                    onChange={this.handle_modal_change}
                />
                <Button disabled={!story_link} onClick={this.add_story}>
                    Submit
                </Button>
            </div>
        );
    };

    send_email = () => {
        const details = this.state.teacherDetails;
        this.props.history.push({
            pathname: '/createemail',
            state: {
                name: details.first_name,
                email: details.email
            }
        });
    };

    toggle_story_link = () =>
        this.setState({ add_story_open: !this.state.add_story_open, story_link: '' });

    toggle_raw_data = () => this.setState({ show_raw_data: !this.state.show_raw_data });

    render_raw_data = () => {
        const teacher_profile = this.state.teacherDetails;
        const { show_raw_data, raw_data_search } = this.state;
        if (show_raw_data) {
            const { raw_data } = teacher_profile;
            const object_keys = Object.keys(raw_data).sort();
            const filtered_keys = object_keys.filter(
                (k) => k.toLowerCase().indexOf(raw_data_search.toLowerCase()) !== -1
            );
            return (
                <div style={{ height: '80vh' }}>
                    <CustomInput
                        label="Search key value"
                        style={{ width: '500px' }}
                        value={raw_data_search}
                        name="raw_data_search"
                        onChange={this.handle_modal_change}
                    />
                    <div className="cus-table" style={{ marginBottom: '20px' }}>
                        <div className="ct-row ct-h">
                            <div className="ct-col ct-hf ct-la ct-pl-10">Key</div>
                            <div className="ct-col ct-la ct-pl-10">Value</div>
                        </div>
                        {filtered_keys.map((key_val, index) => {
                            const val = raw_data[key_val];
                            if (typeof val !== 'object') {
                                return (
                                    <div className="ct-row" key={index + key_val}>
                                        <div className="ct-col ct-hf ct-bw ct-sm-font ct-la ct-pl-10">
                                            <HighlightSearch full_text={key_val} search_text={raw_data_search} />
                                        </div>
                                        <div className="ct-col ct-bw ct-sm-font ct-la ct-pl-10">{val}</div>
                                    </div>
                                );
                            }
                        })}
                    </div>
                </div>
            );
        }
        return null;
    };

    ban_user = () =>
        this.setState({ add_story_loading: true }, () => {
            const url = get_api_url('ban_teacher');
            const { uuid, ban_reason } = this.state;
            const payload = { teacher_uuid: uuid, reason: ban_reason };
            post_api(url, payload, true)
                .then(() => {
                    this.toggle_ban();
                    this.props.get_teacher_profile_details(uuid);
                    this.setState({ add_story_loading: false });
                })
                .catch((e) => {
                    console.log(e);
                    this.setState({ add_story_loading: false });
                });
        });

    unban_user = () =>
        this.setState({ add_story_loading: true }, () => {
            const url = get_api_url('unban_teacher');
            const { uuid } = this.state;
            const payload = { teacher_uuid: uuid };
            post_api(url, payload, true)
                .then(() => {
                    this.toggle_unban();
                    this.props.get_teacher_profile_details(uuid);
                    this.setState({ add_story_loading: false });
                })
                .catch((e) => {
                    console.log(e);
                    this.setState({ add_story_loading: false });
                });
        });

    render_unban = () => (
        <div>
            <h4>Are you sure you want to remove ban?</h4>
            <button onClick={this.unban_user}>Confirm</button>
        </div>
    );

    render_ban = () => {
        const { ban_reason } = this.state;
        return (
            <div>
                <h4>Are you sure you want to ban user?</h4>
                <CustomInput
                    label="Enter reason"
                    style={{ width: '500px' }}
                    value={ban_reason}
                    name="ban_reason"
                    onChange={this.handle_modal_change}
                />
                <Button disabled={!ban_reason} onClick={this.ban_user}>
                    Submit
                </Button>
            </div>
        );
    };

    toggle_ban = () => this.setState({ ban_open: !this.state.ban_open, ban_reason: '' });

    toggle_unban = () => this.setState({ unban_open: !this.state.unban_open });

    open_email_logs = () => {
        const search = `?id=${this.state.uuid}`;
        this.props.history.push({
            pathname: '/teacheremaillogs',
            search
        });
    };

    start_loading = () => this.setState({ loading: true });

    stop_loading = () => this.setState({ loading: false });

    change_input = (field_name, value) => {
        this.setState({ gallery: [...this.state.gallery, value] }, () => this.toggle_upload_image());
    };

    render_add_image = () => {
        const { image_url, teacherDetails } = this.state;
        return (
            <div>
                <UploadImage
                    buttonTitle="Select Image"
                    imageUrl={image_url}
                    uuid={teacherDetails.uuid}
                    folder_name="teacher-gallery"
                    image_type="image_url"
                    change_input={this.change_input}
                    start_loading={this.start_loading}
                    stop_loading={this.stop_loading}
                />
            </div>
        );
    };

    set_altered_qa_score = (e) => {
        let { value } = e.target;
        value = Math.min(value, 100);
        value = parseFloat(value);

        if (value > 100) {
            newValue = 100;
        } else if (value < 0) {
            value = 0;
        }
        this.setState({ altered_qa_score: value });
    };

    change_altered_qa_score = () => {
        this.setState({ change_qa_score: true }, () => {
            const url = get_api_url(
                `/v2/cms_user/teachers/${this.state.teacherDetails.uuid}/update_qa_score`,
                '',
                true
            );
            const payload = {
                altered_avg_qa_score: parseFloat(this.state.altered_qa_score.toFixed(2))
            };
            post_api(url, payload, true)
                .then(() => {
                    this.toggle_qa_score();
                    this.reload_details();
                    this.setState({ add_story_loading: false });
                })
                .catch((e) => {
                    console.log(e);
                    this.setState({ add_story_loading: false });
                });
        });
    };

    render_update_altered_qa_score = () => (
        <>
            <div style={{ margin: '10px 0' }}>
                <CustomInput
                    label="Enter QA Score"
                    onChange={this.set_altered_qa_score}
                    name="qa_score"
                    type="number"
                    value={this.state.altered_qa_score}
                    style={{ width: '260px' }}
                />
            </div>
            <button onClick={this.change_altered_qa_score}>Save</button>
        </>
    );

    boost_reco_score = () =>
        this.setState({ loading: true }, () => {
            const { teacherDetails, boosted_score, manual_boost_type } = this.state;
            const path = `/v2/recommendations/internal/1on1/teachers/manual_boost_${manual_boost_type}`;
            const url = get_api_url(path, false, true);
            const payload = {
                teacher_id: teacherDetails.id,
                score: parseInt(boosted_score)
            };
            put_api(url, payload, true)
                .then((res) => {
                    this.setState({
                        loading: false
                    });
                    this.closeManualBoost();
                    this.closeBoostConfirmation();
                    this.props.get_teacher_profile_details(teacherDetails.uuid);
                })
                .catch((e) => {
                    this.props.set_notification_variable(
                        true,
                        'error',
                        'Error occured while changing reco scores'
                    );
                    this.setState({
                        loading: false
                    });
                    this.closeManualBoost();
                    this.closeBoostConfirmation();
                    console.log(e);
                });
        });

    onCommonChange = (e) => {
        const key = e.target.name;
        const { value } = e.target;
        this.setState({ [key]: value, create_err: false });
    };

    render_manual_boost = () => {
        const { boosted_score, manual_boost_type } = this.state;
        return (
            <div>
                <div
                    style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}
                >
                    <h4>Boost Reco Scores</h4>
                </div>
                <br />
                <div className="display-flex-between">
                    <CustomSingleSelect
                        label="Type of Score"
                        options={teacher_reco_scores}
                        onChange={this.onCommonChange}
                        value={manual_boost_type}
                        name="manual_boost_type"
                    />
                    <CustomInput
                        label="Enter score *"
                        onChange={this.onCommonChange}
                        name="boosted_score"
                        value={boosted_score}
                    />
                </div>
                <Button
                    disabled={!boosted_score}
                    onClick={() =>
                        this.setState({ manual_boost_confirmation: !this.state.manual_boost_confirmation })
                    }
                >
                    Submit
                </Button>
            </div>
        );
    };

    render_boost_confirmation = () => {
        const teacher_profile = this.state.teacherDetails;

        return (
            <div>
                <h4>
                    Are you sure you want to boost the score for :
                    <strong>{`${teacher_profile.first_name} ${teacher_profile.last_name}`}</strong>
                </h4>
                <div className="display-flex-between">
                    <Button onClick={this.boost_reco_score}>Yes</Button>
                    <Button onClick={this.closeManualBoost}>No</Button>
                </div>
            </div>
        );
    };

    render() {
        const { add_boost_score, manual_boost_confirmation } = this.state;
        const teacher_profile = this.state.teacherDetails;
        const teacher_slug_url = `${get_domain()}/teacher/${teacher_profile.slug}`;
        const no_of_updates = Object.keys(this.state.teacher_compare_details).length;
        const meta_title = teacher_profile.first_name
            ? `${teacher_profile.first_name}'s Profile`
            : 'Teacher Profile';
        if (
            this.props.teacher_profile_status === 'loading' ||
            this.state.loading ||
            this.props.config_status === 'loading'
        ) {
            return <CustomLoading />;
        }
        if (this.state.page_error || this.props.teacher_profile_status === 'fail') {
            return <div className="custom-page-center">Please enter valid url</div>;
        }
        if (this.state.edit_profile) {
            return (
                <EditTeacherProfile
                    close_edit={this.close_edit}
                    teacher_details={this.state.teacherDetails}
                    config_values={this.props.config_values}
                    set_notification_variable={this.props.set_notification_variable}
                />
            );
        }
        if (this.state.open_approval_page) {
            return (
                <ApproveUpdates
                    close_approve_updates={this.close_approve_updates}
                    teacher_details={this.state.teacher_compare_details}
                    all_teacher_details={this.state.teacherDetails}
                    reload_details={this.reload_details}
                />
            );
        }
        if (this.state.open_availability) {
            return (
                <SingleAvailability
                    name={`${teacher_profile.first_name} ${teacher_profile.last_name}`}
                    uuid={this.state.teacherDetails.uuid}
                    close_availability_graph={this.close_availability_graph}
                />
            );
        }
        const { access_type } = this.props;
        return (
            <CommonHeader
                show_back
                loading={this.state.add_story_loading}
                title="Teacher Profile"
                meta_title={meta_title}
            >
                <CustomModal show={this.state.add_story_open} close={this.toggle_story_link}>
                    {this.render_add_story()}
                </CustomModal>
                <CustomModal show={this.state.show_qa_score} close={this.toggle_qa_score}>
                    {this.render_update_altered_qa_score()}
                </CustomModal>
                <CustomModal show={this.state.show_raw_data} close={this.toggle_raw_data}>
                    {this.render_raw_data()}
                </CustomModal>
                <CustomModal show={this.state.ban_open} close={this.toggle_ban}>
                    {this.render_ban()}
                </CustomModal>
                <CustomModal show={this.state.unban_open} close={this.toggle_unban}>
                    {this.render_unban()}
                </CustomModal>
                <CustomModal show={this.state.add_image_open} close={this.toggle_upload_image}>
                    {this.render_add_image()}
                </CustomModal>
                <CustomModal show={add_boost_score} close={this.closeManualBoost}>
                    {this.render_manual_boost()}
                </CustomModal>
                <CustomModal show={manual_boost_confirmation} close={this.toggle_remove_faq}>
                    {this.render_boost_confirmation()}
                </CustomModal>
                {teacher_profile.is_banned === 1 ? (
                    <div className="banned-user-container">Banned user</div>
                ) : null}
                <div className="teacher-profile-content">
                    <div className="teacher-profile-links">
                        <div className="teacher-profile-image-container">
                            <div className="teacher-profile-image">
                                <ExifOrientationImg
                                    src={teacher_profile.profile_photo}
                                    alt="profile_pic"
                                    onError={image_error_replace}
                                />
                                {/* <img src={teacher_profile.profile_photo} alt="profile_pic" onError={image_error_replace} /> */}
                                {access_type !== 'CONSULT_CONSULTANT' && access_type !== 'BLOG_CONSULTANT' ? (
                                    <div className="teacher-profile-image-change" onClick={this.update_pic}>
                                        Change Photo
                                        <input
                                            type="file"
                                            id="pic-input"
                                            onChange={this.handleFileChange}
                                            name="photo"
                                        />
                                    </div>
                                ) : null}
                            </div>
                            <h4>
                                {teacher_profile.first_name} {teacher_profile.last_name}
                            </h4>
                        </div>
                        <div className="teacher-profile-links-container">
                            {access_type !== 'CONSULT_CONSULTANT' && access_type !== 'BLOG_CONSULTANT' ? (
                                <>
                                    <button onClick={this.toggle_story_link}>Add story</button>
                                    <Button disabled={no_of_updates === 0} onClick={this.open_approve_updates}>
                                        Approve Updates
                                    </Button>
                                    <button onClick={this.open_availability_graph}>Availability</button>
                                    <button onClick={this.open_edit}>Edit profile</button>
                                    <button onClick={this.open_email_logs}>Email Logs</button>
                                    <button onClick={this.impersonate_teacher}>Impersonate</button>
                                    {teacher_profile.slug ? (
                                        <a
                                            className="link-btn"
                                            href={teacher_slug_url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Profile Web View
                                        </a>
                                    ) : null}
                                    <button onClick={this.toggle_raw_data}>Raw Data</button>
                                    <button onClick={this.send_email}>Send Email</button>
                                    <button onClick={this.open_teacher_sessions}>Sessions list</button>
                                    <a className="link-btn" href={`/user_timeline/teacher/${teacher_profile.uuid}`}>
                                        Timelines Events
                                    </a>
                                    <button onClick={this.open_teacher_transactions}>Transactions</button>
                                    <button onClick={this.update_resume}>Update Resume</button>
                                    <input
                                        type="file"
                                        id="resume-input"
                                        onChange={this.handleFileChange}
                                        name="resume"
                                    />
                                    <button onClick={this.update_intro}>Update Video</button>
                                    <input
                                        type="file"
                                        id="video-input"
                                        onChange={this.handleFileChange}
                                        name="video"
                                    />
                                    {teacher_profile.is_banned === 1 ? (
                                        <button onClick={this.toggle_unban}>Remove Ban</button>
                                    ) : (
                                        <button onClick={this.toggle_ban}>Ban User</button>
                                    )}
                                </>
                            ) : null}
                            <button onClick={this.openChatScreen}>Chat</button>
                            <button onClick={this.openManualBoost}>Manual Boost</button>
                            <button onClick={this.openNotificationPreferences}>Notification Preferences</button>
                            <button
                                onClick={() =>
                                    this.props.history.push(
                                        `/email_timeline/teacher/${this.state.teacherDetails.uuid}`
                                    )
                                }
                            >
                                Emails Timeline
                            </button>
                        </div>
                    </div>
                    <div className="teacher-profile-details">
                        <h4>Intro to students:</h4>
                        {this.render_intro()}
                        {this.render_details()}
                    </div>
                </div>
            </CommonHeader>
        );
    }
}

const mapStateToProps = (state) => ({
    teacher_profile_uuid: state.teachers.approved_teachers_status,
    teacher_profile_details: state.teachers.teacher_profile_details,
    teacher_profile_status: state.teachers.teacher_profile_status,
    config_status: state.loading.config_status,
    config_values: state.home.config_values,
    access_type: state.home.access_type
});

const mapDispatchToProps = (dispatch) => ({
    get_teacher_profile_details: (status) => {
        dispatch(actions.get_teacher_profile_details(status));
    },
    update_teacher_files: (id, key, payload) => {
        dispatch(actions.update_teacher_files(id, key, payload));
    },
    impersonate_user: (id, type) => {
        dispatch(actions.impersonate_user(id, type));
    },
    set_notification_variable: (show, type, mes) => {
        dispatch(actions.set_notification_variable(show, type, mes));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(TeacherProfile);
