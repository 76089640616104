import React from 'react';

export function SubmitIcon() {
  return (
    <svg
      className="me-1 -ms-1 w-5 h-5"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export function AddIcon() {
  return (
    <svg
      className="w-6 h-6 text-gray-800 dark:text-white"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 12h14m-7 7V5"
      />
    </svg>
  );
}

export function CloseIcon() {
  return (
    <svg
      className="w-4 h-4 text-gray-800"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
      />
    </svg>
  );
}

export function StarIcon({ rating = 0, value = 0 }) {
  return (
    <svg
      className="w-6 h-6 text-gray-800 dark:text-white fill-current"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        stroke={rating > value ? `orange` : `gray`}
        strokeWidth="2"
        fill={rating > value ? `yellow` : `gray`}
        d="M11 5.1a1 1 0 0 1 2 0l1.7 4c.1.4.4.6.8.6l4.5.4a1 1 0 0 1 .5 1.7l-3.3 2.8a1 1 0 0 0-.3 1l1 4a1 1 0 0 1-1.5 1.2l-3.9-2.3a1 1 0 0 0-1 0l-4 2.3a1 1 0 0 1-1.4-1.1l1-4.1c.1-.4 0-.8-.3-1l-3.3-2.8a1 1 0 0 1 .5-1.7l4.5-.4c.4 0 .7-.2.8-.6l1.8-4Z"
      />
    </svg>
  );
}

export function InfoIcon() {
  return (
    <svg
      data-tooltip-id="my-tooltip"
      data-tooltip-content="Info"
      className="w-6 h-6"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <svg
        className="w-6 h-6 text-gray-800 dark:text-white"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="gray"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M10 11h2v5m-2 0h4m-2.6-8.5h0M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
      </svg>
    </svg>
  );
}

export function EyeIcon() {
  return (
    <svg
      className="w-6 h-6"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <svg
        className="w-6 h-6 text-gray-800 dark:text-white"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="green"
          strokeWidth="2"
          d="M21 12c0 1.2-4 6-9 6s-9-4.8-9-6c0-1.2 4-6 9-6s9 4.8 9 6Z"
        />
        <path stroke="green" strokeWidth="2" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
      </svg>
    </svg>
  );
}

export function EditIcon() {
  return (
    <svg
      className="w-6 h-6"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <svg
        className="w-6 h-6 text-gray-800 dark:text-white"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="orange"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="m14.3 4.8 2.9 2.9M7 7H4a1 1 0 0 0-1 1v10c0 .6.4 1 1 1h11c.6 0 1-.4 1-1v-4.5m2.4-10a2 2 0 0 1 0 3l-6.8 6.8L8 14l.7-3.6 6.9-6.8a2 2 0 0 1 2.8 0Z"
        />
      </svg>
    </svg>
  );
}

export function DeleteIcon() {
  return (
    <svg
      className="w-6 h-6"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <svg
        className="w-6 h-6 text-gray-800 dark:text-white"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="red"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M5 7h14m-9 3v8m4-8v8M10 3h4a1 1 0 0 1 1 1v3H9V4a1 1 0 0 1 1-1ZM6 7h12v13a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7Z"
        />
      </svg>
    </svg>
  );
}

export function AutomatedMessageIcon() {
  return (
    <svg
      className="max-w-[24px] w-full"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2787_64392)">
        <path
          d="M12 0C5.37063 0 0 5.37063 0 12C0 18.6294 5.37063 24 12 24C18.6294 24 24 18.6294 24 12C24 5.37063 18.6294 0 12 0ZM13.6504 19.1049C13.6504 19.4685 13.3427 19.7762 12.979 19.7762H11.049C10.6853 19.7762 10.3776 19.4685 10.3776 19.1049V17.0909C10.3776 16.7273 10.6853 16.4196 11.049 16.4196H12.979C13.3427 16.4196 13.6504 16.7273 13.6504 17.0909V19.1049ZM13.6504 14.1538C13.6504 14.5175 13.3427 14.8252 12.979 14.8252H11.049C10.6853 14.8252 10.3776 14.5175 10.3776 14.1538V5.76224C10.3776 5.3986 10.6853 5.09091 11.049 5.09091H12.979C13.3427 5.09091 13.6504 5.3986 13.6504 5.76224V14.1538Z"
          fill="#3C310C"
        />
      </g>
      <defs>
        <clipPath id="clip0_2787_64392">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function BackIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 12 21" fill="none">
      <path
        d="M9.60938 20.3906C9.86719 20.6484 10.1953 20.7891 10.582 20.7891C11.3555 20.7891 11.9766 20.1797 11.9766 19.4062C11.9766 19.0195 11.8125 18.668 11.543 18.3984L3.33984 10.3828L11.543 2.39062C11.8125 2.12109 11.9766 1.75781 11.9766 1.38281C11.9766 0.609375 11.3555 0 10.582 0C10.1953 0 9.86719 0.140625 9.60938 0.398438L0.492188 9.30469C0.164062 9.60938 0.0117188 9.98438 0 10.3945C0 10.8047 0.164062 11.1562 0.492188 11.4727L9.60938 20.3906Z"
        fill="black"
      />
    </svg>
  );
}
export function DownloadIcon() {
  return (
    <svg width="22" height="22" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25"
        stroke="#321A50"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.25 7.49969L9 11.2497L12.75 7.49969"
        stroke="#321A50"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 11.25V2.25"
        stroke="#321A50"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function EditBlackIcon() {
  return (
    <svg
      className="w-8 h-8"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <svg
        className="w-8 h-8 text-gray-800 dark:text-white"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="m14.3 4.8 2.9 2.9M7 7H4a1 1 0 0 0-1 1v10c0 .6.4 1 1 1h11c.6 0 1-.4 1-1v-4.5m2.4-10a2 2 0 0 1 0 3l-6.8 6.8L8 14l.7-3.6 6.9-6.8a2 2 0 0 1 2.8 0Z"
        />
      </svg>
    </svg>
  );
}

export function Ratingstar({ filled, onClick }) {
  return (
    <svg
      width="36"
      height="28"
      viewBox="0 0 36 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      style={{ cursor: 'pointer', fill: filled ? '#000' : '#FFF' }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4653 25.2446C17.174 25.0915 16.826 25.0915 16.5347 25.2446L9.38891 29.0014C8.65528 29.3871 7.79785 28.7641 7.93796 27.9472L9.30268 19.9903C9.35831 19.6659 9.25077 19.3349 9.01508 19.1052L3.23404 13.4701C2.64053 12.8915 2.96804 11.8835 3.78826 11.7644L11.7775 10.6035C12.1032 10.5561 12.3847 10.3516 12.5304 10.0564L16.1033 2.81698C16.4701 2.07374 17.5299 2.07374 17.8967 2.81699L21.4696 10.0564C21.6153 10.3516 21.8968 10.5561 22.2226 10.6035L30.2117 11.7644C31.032 11.8835 31.3595 12.8915 30.766 13.4701L24.9849 19.1052C24.7492 19.3349 24.6417 19.6659 24.6973 19.9903L26.062 27.9472C26.2022 28.7641 25.3447 29.3871 24.6111 29.0014L17.4653 25.2446Z"
        stroke="#000"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function CheckMarkIcon({ style }) {
  const greenTickUrl = 'https://images.myyogateacher.com/icons/ic-checkmark-48.png';
  return <img src={greenTickUrl} style={style} alt="icon" />;
}

export function CrossIcon({ style }) {
  const redTickUrl = 'https://images.myyogateacher.com/icons/ic-delete-48.png';
  return <img src={redTickUrl} style={style} alt="icon" />;
}
export function CopyClick() {
  return (
    <svg
      className="w-6 h-6 text-gray-800 dark:text-white"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path
        fillRule="evenodd"
        d="M18 3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1V9a4 4 0 0 0-4-4h-3a1.99 1.99 0 0 0-1 .267V5a2 2 0 0 1 2-2h7Z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M8 7.054V11H4.2a2 2 0 0 1 .281-.432l2.46-2.87A2 2 0 0 1 8 7.054ZM10 7v4a2 2 0 0 1-2 2H4v6a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export function WhatsappIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_8283_10620)">
        <path
          d="M8 0.40625C3.80625 0.40625 0.40625 3.80625 0.40625 8C0.40625 9.53438 0.8625 10.9656 1.64688 12.1594L0.66875 15.4719L4.10938 14.5188C5.24687 15.2 6.57812 15.5906 8 15.5906C12.1938 15.5906 15.5938 12.1906 15.5938 7.99687C15.5938 3.80625 12.1938 0.40625 8 0.40625Z"
          fill="#25D366"
        />
        <path
          d="M12.172 10.0844C12.1626 9.98443 12.1032 9.89693 12.0126 9.8563C11.4001 9.57505 10.7845 9.2938 10.172 9.01255C10.1032 8.9813 10.0189 8.99693 9.96886 9.0563L9.13761 9.9938C9.07198 10.0688 8.96886 10.0938 8.87824 10.0563C8.44699 9.87818 7.86573 9.57818 7.27823 9.07193C6.57511 8.4688 6.16261 7.82193 5.93136 7.40005C6.04698 7.32193 6.36261 7.08755 6.50324 6.65318C6.50324 6.65005 6.50636 6.64693 6.50636 6.64693C6.59386 6.37818 6.57511 6.08755 6.47824 5.82193C6.31261 5.3813 5.98136 4.52818 5.85324 4.39693C5.83136 4.37505 5.80636 4.35318 5.80636 4.35318C5.71261 4.2688 5.59073 4.22193 5.46261 4.21568C5.41886 4.21255 5.37198 4.21255 5.32199 4.21255C5.17824 4.20943 5.05011 4.20943 4.95636 4.22193C4.69074 4.25943 4.49698 4.43755 4.37511 4.58755C4.22823 4.76568 4.05323 5.0313 3.93761 5.3813C3.92823 5.41255 3.91886 5.44068 3.90948 5.47193C3.78136 5.91568 3.80636 6.39068 3.95948 6.82818C4.10323 7.23755 4.31261 7.72505 4.62824 8.24068C5.16261 9.1188 5.74386 9.66255 6.13761 10.0219C6.58136 10.4313 7.13136 10.9344 8.00324 11.3532C8.80949 11.7407 9.54074 11.8844 10.0314 11.9438C10.2314 11.9626 10.6001 11.9657 11.022 11.8063C11.2157 11.7313 11.3782 11.6407 11.5095 11.5438C11.8564 11.2969 12.1001 10.9251 12.1626 10.5063C12.1626 10.5032 12.1626 10.5001 12.1626 10.4969C12.1845 10.3376 12.1814 10.2001 12.172 10.0844Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_8283_10620">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function SMSIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-message-square"
    >
      <path d="M21 15a2 2 0 0 1-2 2H7l-5 5V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
      <line x1="8" y1="9" x2="16" y2="9" stroke="currentColor" strokeWidth="1" />
      <line x1="8" y1="12" x2="14" y2="12" stroke="currentColor" strokeWidth="1" />
      <line x1="8" y1="15" x2="12" y2="15" stroke="currentColor" strokeWidth="1" />
    </svg>
  );
}
