import Packet from "../enums/Packet"
import QueuedPacket from "../packets/QueuedPacket"
import RawPacket from "../packets/RawPacket"
import { SMSChannel, SMSChannelMsg, SMSChannelPkt } from "../packets/SMSChannel"
import { v4 as uuidV4 } from 'uuid';
import ChatClient from "./ChatClient";
import { logger } from "../../utils/Logger";
import { CallbackHandler } from "../callbacks/handler";

export default class SMSHandler {

    static shared = new SMSHandler()

    fetchSMSGroups = (): Promise<{ groups: Array<SMSChannel> }> => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.SMS
        pkt[Packet.Keys.SUB_TYPE] = Packet.SMS.Types.LIST_GROUPS
        pkt[Packet.Keys.ID] = uuidV4();

        return new Promise((resolve, reject) => {
            if (!ChatClient.shared.sendPacket(pkt, resolve, true)) {
                reject({ error: "packet could not be sent to server" });
            }
        });
    }

    fetchSMSArchive = (studentUUID: string): Promise<{ messages: Array<SMSChannelMsg> }> => {
        const pkt: RawPacket = {};
        pkt[Packet.Keys.TYPE] = Packet.Types.SMS
        pkt[Packet.Keys.SUB_TYPE] = Packet.SMS.Types.ARCHIVE;
        pkt[Packet.Common.USER] = studentUUID;
        pkt[Packet.Keys.ID] = uuidV4();

        return new Promise((resolve, reject) => {
            if (!ChatClient.shared.sendPacket(pkt, resolve, true)) {
                reject({ error: "packet could not be sent to server" });
            }
        });
    }

    onPacketReceived = async (res: RawPacket, queued?: QueuedPacket) => {
        switch (res[Packet.Keys.SUB_TYPE]) {
            case Packet.SMS.Types.LIST_GROUPS:
                {
                    const rawItems: Array<RawPacket> = res[Packet.SMS.Keys.GROUPS] || [];

                    const parsedItems: Array<SMSChannel> = rawItems.map((rawItem: RawPacket) => {
                        return {
                            id: rawItem[Packet.Common.CHANNEL],
                            name: rawItem[Packet.Account.Keys.NAME],
                            avatar: rawItem[Packet.Account.Keys.AVATAR],
                            unread: 0,
                            student: rawItem[Packet.Common.USER],
                            lastActivity: rawItem[Packet.Account.Keys.SECONDS] || 0,
                            is_active: rawItem['is_active'] || 0,
                            domain: rawItem['domain'] || '',
                            type: rawItem['typ'] || ''
                        }
                    });

                    queued?.resolve?.({ groups: parsedItems })
                }
                break;
            case Packet.SMS.Types.ARCHIVE:
                {
                    const rawMessages: Array<RawPacket> = res[Packet.Message.Keys.MESSAGES] || [];
                    const messages: Array<SMSChannelMsg> = rawMessages.map((rawMessage) => {

                        const profile: RawPacket = rawMessage[Packet.Message.Keys.PROFILE]

                        return {
                            id: rawMessage[Packet.Keys.ID],
                            text: rawMessage[Packet.Message.Data.TEXT],
                            media: rawMessage[Packet.Message.Data.MEDIA],
                            timestamp: rawMessage[Packet.Common.TIMESTAMP],
                            toStudent: rawMessage[Packet.Query.ORDER] === Packet.Values.TRUE,
                            type: rawMessage.frm.typ,
                            status: rawMessage[Packet.Message.Data.STATUS],
                            profile: {
                                user: profile[Packet.Common.USER],
                                name: profile[Packet.Account.Keys.NAME],
                                avatar: profile[Packet.Account.Keys.AVATAR],
                                role: profile[Packet.Account.Keys.ROLE]
                            }
                        }
                    })

                    queued?.resolve?.({ messages: messages })
                }
                break;
            case Packet.SMS.Types.CHAT:
                {
                    const fromProf = res[Packet.Common.FROM]
                    const toProf = res[Packet.Common.TO]

                    const item: SMSChannelPkt = {
                        id: res[Packet.Keys.ID],
                        channel: res[Packet.Common.CHANNEL],
                        timestamp: res[Packet.Common.TIMESTAMP],
                        from: {
                            user: fromProf[Packet.Common.USER],
                            name: fromProf[Packet.Account.Keys.NAME],
                            avatar: fromProf[Packet.Account.Keys.AVATAR],
                            role: fromProf[Packet.Account.Keys.ROLE],
                            type: fromProf['typ']
                        },
                        to: {
                            user: toProf[Packet.Common.USER],
                            name: toProf[Packet.Account.Keys.NAME],
                            avatar: toProf[Packet.Account.Keys.AVATAR],
                            role: toProf[Packet.Account.Keys.ROLE],
                        },
                        text: res[Packet.Message.Data.TEXT],
                        media: res[Packet.Message.Data.MEDIA],
                        status: res[Packet.Message.Data.STATUS]

                    }

                    CallbackHandler.shared.callOnSMSChannelData(item)
                }
                break;
            default:
                {
                    logger.leaveBreadcrumb("SMSHandler: unrecognized", {
                        type: res
                    })
                    logger.error("SMSHandler: unrecognized");
                }
                break;
        }
    }
}