import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import CustomLoading from '../util_components/custom_loading';
import { getCookie } from '../../util_functions';
import * as actions from '../../redux/action_creators';
import { ianaTimezones } from '../../constants';

import Drawer from '../views/drawer';
import StudentProfiles from '../views/student_profiles';
import TeachersList from '../views/teachers/teachers_list';
import TeacherProfile from '../views/teachers/teacher_profile';
import TeacherRepeatSessions from '../views/teachers/repeat_sessions';
import TeacherTransactions from '../views/teachers/teacher_transactions';
import TeacherEmailLogs from '../views/teachers/teacher_email_logs';
import TeacherAreaMap from '../views/teachers/teacher_area_map';

import TeacherAvailability from '../views/reports/availability_all_teachers';
import ConsultTeacherAvailability from '../views/reports/availability_all_teachers/consult_availability';
import StudentsAndSessions from '../views/reports/students_sessions';
import AllStudentTransactions from '../views/reports/all_student_transactions';
import SessionsReport from '../views/reports/sessions_report';
import Referrals from '../views/reports/referrals';
import TeacherSessionsStats from '../views/reports/teacher_sessions_stats';
import StudentSessionsStats from '../views/reports/student_sessions_stats';
import CareMessages from '../views/reports/care_messages';
import CohortTimeBased from '../views/reports/cohort_time_based';
import KeyMetrics from '../views/reports/key_metrics';
import SessionsWeekly from '../views/reports/sessions_weekly';
import GroupSessionsReport from '../views/reports/group_sessions_report';
import GroupSessionsByDate from '../views/reports/grp_sess_by_date';
import EmailDeliveryReport from '../views/reports/email_delivery_report';
import EmailDeliveryReportDay from '../views/reports/email_delivery_report_day';
import Replacement from '../views/reports/replacement';
import NewUsers from '../views/reports/new_users';
import SmsReport from '../views/reports/sms_report';
import FlaggedSMS from '../views/reports/flagged_sms';
import FailedRecurringTransactions from '../views/reports/failed_recurring_transactions';
import BannedStudents from '../views/reports/banned_students';
import MembershipCancellations from '../views/reports/membership_cancellations';
import BlockedMessages from '../views/profanity_filter/blocked_messages';
import CouponOffers from '../views/coupons';
import CouponOfferDetails from '../views/coupons/details';
import Concierge from '../views/reports/concierge';

import FutureSessions from '../views/sessions/future_sessions';
import PastSessions from '../views/sessions/past_sessions';
import SessionViewer from '../views/sessions/session_viewer';
import UserSessions from '../views/sessions/user_sessions';
import SingleSessionDetails from '../views/sessions/single_session_details';
import ReplacementSessions from '../views/sessions/replacement_sessions';
import ReplacementSessionTeachers from '../views/sessions/replacement_session_teachers';
import CancelledSessions from '../views/sessions/cancelled_sessions';
import CropRecording from '../views/sessions/crop_recording';
import FriendsSessions from '../views/sessions/friends_sessions';
import PastFriendsSessions from '../views/sessions/past_friends_sessions';
import RecommendationsPlayground from '../views/sessions/RecommendationsPlayground';

import GroupSessionDetails from '../views/classes/class_details';
import GroupSessions from '../views/classes/future_classes';
import RepeatGroupsessions from '../views/classes/repeat_group_classes.jsx';
import CreateGroupSessions from '../views/classes/create_group_sessions';
import PastGroupSessions from '../views/classes/past_classes';
import RecommendedGroupSessions from '../views/classes/recommeded_classes';

import StudentsList from '../views/students/students_list';
import LiveUsers from '../views/students/live_users';
import StudentProfile from '../views/students/student_profile';
import StudentProfileTests from '../views/students/student_tests';
import StudentEmailLogs from '../views/students/email_logs';
import AddRoadmap from '../views/students/add_roadmap';
import StudentTransactions from '../views/students/student_transactions';
import CreditHistory from '../views/students/credit_history';
import Preferences from '../views/students/notification_preferences';
import StudentRepeatSession from '../views/students/repeat_sessions';
import StudentNotifications from '../views/students/notifications';

import EmailTemplates from '../views/email_templates';
import CreateEmail from '../views/other/create_email';
import BlockedDevices from '../views/other/blocked_devices';
import DownloadData from '../views/other/download_data';
import NewTesting from '../views/other/testing';
import Invalidate from '../views/other/invalidate';
import TeacherPayments from '../views/other/upload_teacher_payment';
import Inventory from '../views/other/inventory';
import PressPages from '../views/other/press_pages';
import ProfanityList from '../views/profanity_filter/profanity_list';

import EmailTemplatesNew from '../views/emails/templates';

import HealthKeywords from '../views/classes/health_keywords';
import GroupClassTypes from '../views/classes/group_class_types';
import GroupClassPages from '../views/classes/group_class_pages';
import ClassPageDetails from '../views/classes/class_page_details';

import SplitTesting from '../views/split_testing';
import SplitTestingV2 from '../views/split_testing_v2';
import UploadImages from '../views/other/upload_images';

import Announcements from '../views/announcements';
import AnnouncementDetails from '../views/announcements/details';
import MinorVersionDetails from '../views/announcements/minor_version_details';

import Workshops from '../views/workshops';
import WorkshopDetails from '../views/workshops/workshop_details';
import WorkshopSession from '../views/workshops/workshop_session';

import PastConsults from '../views/consults/past';
import FutureConsults from '../views/consults/future';
import ConsultDetails from '../views/consults/details';

import CommunityClassesRequest from '../views/community_classes';
import PromoteGrpClass from '../views/promote_grp_class';

import Groups from '../views/ywf_groups';
import GroupDetails from '../views/ywf_groups/group_details';

import UserTimeline from '../views/user_timeline';

import ContentTags from '../views/content/tags';
import ContentTagsBySlug from '../views/content/tags_content';
import ContentBlogs from '../views/content/blogs';

import TagAssociations from '../views/content/tag_associations';

import './home.css';

import { ChatWindowManager } from '../chat/chat';
import InactiveMembers from '../views/reports/inactive_members';
import LiveKitUsers from '../views/LiveKit_users';
import MagicLinkCampaigns from '../views/magic_link_campaigns';
import index from '../views/FeedbackForm';
import CreateSessionFeedback from '../views/FeedbackForm/createSessionFeedback';
import SessionFeedbackFormDetails from '../views/FeedbackForm/SessionFeedbackFormDetails';
import EditSessionFeedback from '../views/FeedbackForm/EditSessionFeedback';
import ChatClient from '../../../myt_chat/src/chat/network/ChatClient';
import { CallbackHandler } from '../../../myt_chat/src/chat/callbacks/handler';
import UserRole from '../../../myt_chat/src/chat/enums/UserRole';
import MembershipCreditHistory from '../views/students/student_profile/MembershipCreditHistory';
import TeacherNotificationPreferences from '../views/teachers/notification_preferences/TeacherNotificationPreferences';
import TeacherNotificationPreferencesHistory from '../views/teachers/notification_preferences/TeacherNotificationPreferencesHistory';
import GptSessionNotes from '../views/LLM_Session';
import SessionSummary from '../views/LLM_Session/SessionSummary';
import GroupChats from '../views/chat_groups';
import ShortUrlLinks from '../views/short_url_links';
import ZoomMeetingUsers from '../views/Zoom_meetings_users';
import email_timeline from '../views/email_timeline/index.jsx';
import TimezoneDropdown from '../util_components/TimezoneDropDown';
import VideoChapters from '../views/sessions/VideoChapters';

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      drawer_open: window.innerWidth > 700
    };
  }

  smsHandler = (item) => {
    if (
      item.from.role === UserRole.STUDENT &&
      item.from.user === '1f4d13c1-4710-420f-992f-f6e9d258932b'
    ) {
      const alertText = `Incoming SMS \nStudent: ${item.from.name}\nText: ${item.text}`;
      alert(alertText);
    } else {
      console.log(
        `SMS received from: ${item.from.role}:${item.from.name} to ${item.to.role}:${item.to.name}, text: ${item.text}`
      );
    }
  };

  componentDidMount() {
    this.props.get_permissions();
    if (this.props.config_status === 'none') {
      this.props.get_config();
    }

    ChatClient.shared.initialize();
    CallbackHandler.shared.registerSMSHandler(this.smsHandler);
  }

  componentWillUnmount() {
    CallbackHandler.shared.unregisterSMSHandler(this.smsHandler);
  }

  toggle_drawer = () => {
    this.setState({ drawer_open: !this.state.drawer_open });
  };

  set_time_zone = (e) => this.props.set_home_variable('iana_timezone', e.target.value);

  render_switch = () => {
    const { access_pages } = this.props;
    const single_path = `/${window.location.pathname.split('/')[1]}`;
    if (access_pages.indexOf(single_path) === -1) {
      const url = access_pages[0];
      return <Redirect to={url} />;
    }
    return (
      <Switch>
        <Route path="/student_profiles" component={StudentProfiles} />
        <Route path="/studentsandsessions" component={StudentsAndSessions} />
        <Route path="/allstudenttransactions" component={AllStudentTransactions} />
        <Route path="/teacherprofile" component={TeacherProfile} />
        <Route path="/teacherslist" component={TeachersList} />
        <Route path="/teachertransactions" component={TeacherTransactions} />
        <Route path="/availability/consult" component={ConsultTeacherAvailability} />
        <Route path="/availability" component={TeacherAvailability} />
        <Route exact path="/sessions/backpain" component={FutureSessions} />
        <Route exact path="/pastsessions/backpain" component={PastSessions} />
        <Route path="/sessions" component={FutureSessions} />
        <Route path="/pastsessions" component={PastSessions} />
        <Route path="/usersessions/future" component={UserSessions} />
        <Route
          path="/teacher/notification_preferences"
          component={TeacherNotificationPreferences}
        />
        <Route
          path="/teacher/notification_preferences_history"
          component={TeacherNotificationPreferencesHistory}
        />
        <Route path="/usersessions/past" component={UserSessions} />
        <Route exact path="/groupsessions" component={GroupSessions} />
        <Route exact path="/groupsessions/repeat" component={RepeatGroupsessions} />
        <Route exact path="/backpain/groupsessions" component={GroupSessions} />
        <Route exact path="/backpain/pastgroupsessions" component={PastGroupSessions} />
        <Route exact path="/membership_cancellations" component={MembershipCancellations} />
        <Redirect exact from="/groupsessions/recommended" to="/groupsessions/recommended/future" />
        <Route
          path="/groupsessions/recommended/:page_status"
          component={RecommendedGroupSessions}
        />
        <Route path="/creategroupsessions" component={CreateGroupSessions} />
        <Route path="/pastgroupsessions" component={PastGroupSessions} />
        <Route path="/sessiondetails" component={SingleSessionDetails} />
        <Route path="/recommendations_playground" component={RecommendationsPlayground} />
        <Route
          path="/replacementsessions/:replacementRequestID"
          component={ReplacementSessionTeachers}
        />
        <Route path="/replacementsessions" component={ReplacementSessions} />
        <Route path="/groupsessiondetails" component={GroupSessionDetails} />
        <Route path="/cancelledsessions" component={CancelledSessions} />
        <Route path="/session_replay" component={SessionViewer} />
        <Route exact path="/friends_sessions" component={FriendsSessions} />
        <Route exact path="/friends_sessions/past" component={PastFriendsSessions} />
        <Route path="/users" component={StudentsList} />
        <Route path="/liveusers" component={LiveUsers} />
        <Route exact path="/studentprofile" component={StudentProfile} />
        <Route exact path="/membership_credits_history" component={MembershipCreditHistory} />
        <Route path="/studentprofile/tests/:slug" component={StudentProfileTests} />
        <Route path="/studentemaillogs" component={StudentEmailLogs} />
        <Route path="/addroadmap" component={AddRoadmap} />
        <Route path="/studenttransactions" component={StudentTransactions} />
        <Route path="/sessionsreport" component={SessionsReport} />
        <Route path="/referrals" component={Referrals} />
        <Route path="/teachersessionstats" component={TeacherSessionsStats} />
        <Route path="/studentsessionstats" component={StudentSessionsStats} />
        <Route path="/caremessages" component={CareMessages} />
        <Route path="/credithistory" component={CreditHistory} />
        <Route path="/student/notification_preferences" component={Preferences} />
        <Route path="/emailtemplates" component={EmailTemplates} />
        <Route path="/cohorttimebased" component={CohortTimeBased} />
        <Route path="/keymetrics" component={KeyMetrics} />
        <Route path="/weekly_report" component={SessionsWeekly} />
        <Route path="/groupsessionsreport" component={GroupSessionsReport} />
        <Route path="/grpsessionreportdate" component={GroupSessionsByDate} />
        <Route path="/createemail" component={CreateEmail} />
        <Route path="/croprecording" component={CropRecording} />
        <Route path="/split_testing" component={SplitTesting} />
        <Route path="/split_testing/past" component={SplitTesting} />
        <Route path="/split_test_v2" component={SplitTestingV2} />
        <Route path="/upload_images" component={UploadImages} />
        <Route path="/workshops" component={Workshops} />
        <Route exact path="/workshop/:uuid" component={WorkshopDetails} />
        <Route exact path="/workshop/session" component={WorkshopSession} />
        <Route exact path="/emaildeliveryreport" component={EmailDeliveryReport} />
        <Route exact path="/emaildeliveryreport/day" component={EmailDeliveryReportDay} />
        <Route path="/health_keywords" component={HealthKeywords} />
        <Route path="/group_class_types" component={GroupClassTypes} />
        <Route path="/repeat_sessions/teacher" component={TeacherRepeatSessions} />
        <Route path="/repeat_sessions" component={StudentRepeatSession} />
        <Route path="/group_class_pages" component={GroupClassPages} />
        <Route path="/backpain/group_class_pages" component={GroupClassPages} />

        <Route path="/group_class_page/:page_slug" component={ClassPageDetails} />
        <Route path="/pastreplacements" component={Replacement} />
        <Route path="/blocked_devices" component={BlockedDevices} />
        <Route exact path="/coupon_offers" component={CouponOffers} />
        <Route exact path="/coupon_offers/:uuid" component={CouponOfferDetails} />
        <Route exact path="/announcements" component={Announcements} />
        <Route exact path="/announcements/:uuid" component={AnnouncementDetails} />
        <Route exact path="/announcements/minor/:uuid" component={MinorVersionDetails} />
        <Route exact path="/newusers" component={NewUsers} />
        <Route exact path="/content/tags" component={ContentTags} />
        <Route exact path="/content/tags/:slug_name" component={ContentTagsBySlug} />
        <Route exact path="/content/blogs" component={ContentBlogs} />
        <Route
          exact
          path="/tag_associations/:association_type/:association_id"
          component={TagAssociations}
        />

        <Route exact path="/consults/past" component={PastConsults} />
        <Route exact path="/consults/future" component={FutureConsults} />
        <Route exact path="/consults/details/:uuid" component={ConsultDetails} />
        <Route exact path="/test" component={NewTesting} />
        <Route exact path="/student/notifications/:uuid" component={StudentNotifications} />
        <Route exact path="/download" component={DownloadData} />
        <Route exact path="/sms_report" component={SmsReport} />
        <Route exact path="/ten_days_inactive_users" component={InactiveMembers} />
        <Route exact path="/banned_students" component={BannedStudents} />
        <Route exact path="/failedrecurringtransactions" component={FailedRecurringTransactions} />
        <Route exact path="/teacheremaillogs" component={TeacherEmailLogs} />
        <Route exact path="/ywf_repeat_groups" component={Groups} />
        <Route exact path="/ywf_repeat_groups/:uuid" component={GroupDetails} />
        <Route exact path="/community_class" component={CommunityClassesRequest} />
        <Route exact path="/gpt_session_notes" component={GptSessionNotes} />
        <Route exact path="/gpt_session_notes/session_summary" component={SessionSummary} />
        <Route exact path="/promote_class" component={PromoteGrpClass} />
        <Route exact path="/profanity_list" component={ProfanityList} />
        <Route exact path="/blockedmessages" component={BlockedMessages} />
        <Route exact path="/teacherareamap" component={TeacherAreaMap} />
        <Route exact path="/inventory" component={Inventory} />
        <Route path="/user_timeline/:user_type/:uuid" component={UserTimeline} />
        <Route exact path="/invalidate" component={Invalidate} />
        <Route exact path="/upload_teacher_payments" component={TeacherPayments} />
        <Route exact path="/concierge/lifestyle" component={Concierge} />
        <Route exact path="/concierge/backpain" component={Concierge} />
        <Route exact path="/press_pages" component={PressPages} />
        <Route exact path="/flagged_sms" component={FlaggedSMS} />
        <Route exact path="/livekit-users" component={LiveKitUsers} />
        <Route exact path="/chat-groups" component={GroupChats} />

        <Route exact path="/zoom-meeting-users" component={ZoomMeetingUsers} />
        <Route exact path="/magic-links" component={MagicLinkCampaigns} />
        <Route exact path="/feedback-forms" component={index} />
        <Route exact path="/session_score_card/create" component={CreateSessionFeedback} />
        <Route exact path="/session_score_card" component={SessionFeedbackFormDetails} />
        <Route exact path="/session_score_card/edit" component={EditSessionFeedback} />
        <Route exact path="/email-templates-list" component={EmailTemplatesNew} />
        <Route exact path="/short-urls" component={ShortUrlLinks} />
        <Route exact path="/session-video-chapter" component={VideoChapters} />
      </Switch>
    );
  };

  render() {
    const drawer_class = this.state.drawer_open ? 'drawer' : 'drawer drawer-close';
    const content_class = this.state.drawer_open ? 'content' : 'content content-full-screen';
    if (this.props.cms_token === '') {
      const url = new URL(window.location.href);
      const redirect = `/?redirect_url=${url.pathname}${url.search}`;
      return <Redirect to={redirect} />;
    }
    return (
      <div className="home-container">
        <div className="header">
          <div className="header-logo-cont">
            <a href="/">
              <img
                className="home-logo"
                src="https://images.myyogateacher.com/web_images/web_prod/mobileHeaderIcon.png"
                alt="logo"
              />
            </a>
            <img
              className="home-menu-icon"
              onClick={this.toggle_drawer}
              src="https://images.myyogateacher.com/website/ic_menu_white.png"
              alt="menu"
            />
          </div>
          <div className="header-email">
            <div className="header-timezone">
              <div className="header-timezone-title">Timezone: </div>
              <TimezoneDropdown
                timezones={ianaTimezones}
                value={this.props.iana_timezone}
                onChange={this.set_time_zone}
                name="timezone"
              />
            </div>
            <div className="header-email-logout">
              <p>{getCookie('cms_email')}</p>
              {/* <div className="home-logout" onClick={this.props.log_out}>
								Log out
							</div> */}
            </div>
          </div>
        </div>
        {this.props.permissions_status === 'success' ? (
          <div className="content-container">
            <div className={drawer_class}>
              <Drawer
                access_pages={this.props.access_pages}
                log_out={this.props.log_out}
                toggle_drawer={this.toggle_drawer}
              />
            </div>
            <div className={content_class}>{this.render_switch()}</div>
          </div>
        ) : (
          <CustomLoading />
        )}
        <ChatWindowManager />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cms_token: state.home.cms_token,
  home_component_index: state.home.home_component_index,
  iana_timezone: state.home.iana_timezone,
  permissions_status: state.loading.permissions_status,
  access_pages: state.home.access_pages,
  config_status: state.loading.config_status
});

const mapDispatchToProps = (dispatch) => ({
  log_out: () => dispatch(actions.log_out()),
  set_home_variable: (key, payload) => dispatch(actions.set_home_variable(key, payload)),
  get_permissions: () => dispatch(actions.get_permissions()),
  get_config: () => dispatch(actions.get_config())
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
