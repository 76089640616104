import React, { Component } from 'react';
import moment from 'moment-timezone';
import CustomLoading from '../../../util_components/custom_loading';
import CustomInput from '../../../util_components/custom_input';
import CustomCheckBox from '../../../util_components/custom_checkbox';
import CustomSingleSelect from '../../../util_components/custom_single_select';
import CustomDatePicker from '../../../util_components/custom_date_picker';
import {
  image_error_replace,
  chunk_array,
  format_to_label_value
} from '../../../../util_functions';
import {
  back_pain_intensity,
  client_height_in_ft,
  client_height_in_inc,
  iana_values,
  phone_no_codes,
  new_yoga_levels,
  new_yoga_types,
  teacher_goals,
  student_timeslots,
  mapped_student_timeslots,
  EthnicityOptions,
  ianaTimezones
} from '../../../../constants';
import { get_api_url } from '../../../../utils/urls';
import { post_api } from '../../../../redux/api_funcs';
import { convertToSentenceCase } from '../../../../utils/formatString';
import TimezoneDropdown from '../../../util_components/TimezoneDropDown';

const ban_options = [
  {
    value: 1,
    label: 'YES'
  },
  {
    value: 0,
    label: 'NO'
  }
];

export default class EditStudentProfile extends Component {
  state = {
    loading: true,
    password: '',
    iana_timezone: '',
    timezones: [],
    ethnicityOptions: [],
    concierge_entered_ethnicity: ''
  };

  componentDidMount() {
    const goals =
      typeof this.props.student_details.yoga_goals === 'object'
        ? this.props.student_details.yoga_goals[0]
        : this.props.student_details.yoga_goals;
    const timezones = [...ianaTimezones];

    const { iana_timezone, concierge_entered_ethnicity } = this.props.student_details;
    const timezone_index = ianaTimezones.findIndex((group) =>
      group.options.some((option) => option.value === this.props.student_details.iana_timezone)
    );

    if (timezone_index === -1) {
      const othersGroupIndex = ianaTimezones.findIndex((group) => group.label === 'Others');

      if (othersGroupIndex !== -1) {
        // Push the new timezone into the existing "Others" group
        timezones[othersGroupIndex].options.push({
          value: iana_timezone,
          label: iana_timezone // Add a meaningful label
        });
      } else {
        // Create a new "Others" group and add the timezone
        timezones.push({
          label: 'Others',
          options: [{ value: iana_timezone, label: iana_timezone }]
        });
      }
    }

    this.setState(
      {
        student_details: { ...this.props.student_details, yoga_goals: goals },
        timezones,
        concierge_entered_ethnicity,
        iana_timezone
      },
      () => {
        this.setState({ loading: false });
      }
    );
  }

  on_box_click = (name, val) => {
    const new_val = val === 1 ? 0 : 1;
    this.setState({
      student_details: {
        ...this.state.student_details,
        [name]: new_val
      }
    });
  };

  handle_change = (e) => {
    this.setState({
      student_details: {
        ...this.state.student_details,
        [e.target.name]: e.target.value
      }
    });
  };

  handle_client_weight_change = (e) => {
    this.setState({
      student_details: {
        ...this.state.student_details,
        onboarding_survey: {
          ...this.state.student_details.onboarding_survey,
          weight: e.target.value
        }
      }
    });
  };

  handle_Pain_duration_change = (e) => {
    this.setState({
      student_details: {
        ...this.state.student_details,
        onboarding_survey: {
          ...this.state.student_details.onboarding_survey,
          back_pain_duration: e.target.value
        }
      }
    });
  };

  handle_back_pain_intensity_change = (e) => {
    this.setState({
      student_details: {
        ...this.state.student_details,
        onboarding_survey: {
          ...this.state.student_details.onboarding_survey,
          back_pain_intensity: e.target.value
        }
      }
    });
  };

  handle_client_height_in_ft_change = (e) => {
    this.setState({
      student_details: {
        ...this.state.student_details,
        onboarding_survey: {
          ...this.state.student_details.onboarding_survey,
          height_feet: +e.target.value
        }
      }
    });
  };

  handle_client_height_in_inc_change = (e) => {
    this.setState({
      student_details: {
        ...this.state.student_details,
        onboarding_survey: {
          ...this.state.student_details.onboarding_survey,
          height_inches: +e.target.value
        }
      }
    });
  };

  handle_back_pain_trigger = (e) => {
    this.setState({
      student_details: {
        ...this.state.student_details,
        onboarding_survey: {
          ...this.state.student_details.onboarding_survey,
          back_pain_trigger: e.target.value
        }
      }
    });
  };

  on_common_change = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handle_date_change = (name, val) => {
    this.setState({
      student_details: {
        ...this.state.student_details,
        [name]: val
      }
    });
  };

  handle_number_change = (e) => {
    this.setState({
      student_details: {
        ...this.state.student_details,
        [e.target.name]: parseInt(e.target.value, 10)
      }
    });
  };

  edit_goals = (item) => {
    const array = this.state.student_details.yoga_goals.split(',');
    const item_index = array.indexOf(item);
    if (item_index < 0) {
      array.push(item);
    } else {
      array.splice(item_index, 1);
    }
    this.setState({
      student_details: {
        ...this.state.student_details,
        yoga_goals: array.join(',')
      }
    });
  };

  edit_pain_area = (item) => {
    const array = this.state.student_details.onboarding_survey.back_pain_location.split(',');
    const item_index = array.indexOf(item);

    if (item_index < 0) {
      array.push(item);
    } else {
      array.splice(item_index, 1);
    }
    this.setState({
      student_details: {
        ...this.state.student_details,
        onboarding_survey: {
          ...this.state.student_details.onboarding_survey,
          back_pain_location: array.join(',')
        }
      }
    });
  };

  edit_back_pain_doctor_diagnosis = (item) => {
    const array =
      this.state.student_details.onboarding_survey.back_pain_doctor_diagnosis.split(',');
    const item_index = array.indexOf(item);

    if (item_index < 0) {
      array.push(item);
    } else {
      array.splice(item_index, 1);
    }
    this.setState({
      student_details: {
        ...this.state.student_details,
        onboarding_survey: {
          ...this.state.student_details.onboarding_survey,
          back_pain_doctor_diagnosis: array.join(',')
        }
      }
    });
  };

  edit_preferred_timeslots = (item, item_index) => {
    const array = JSON.parse(this.state.student_details.preferred_practice_timebands);
    if (item_index < 0) {
      array.push(item);
    } else {
      array.splice(item_index, 1);
    }
    this.setState({
      student_details: {
        ...this.state.student_details,
        preferred_practice_timebands: JSON.stringify(array)
      }
    });
  };

  render_back_pain_doctor_diagnosis = () => {
    const keys_of_back_doctor_diagnosis = Object.keys(
      this.props.config_values?.back_pain_doctor_diagnosis
    );
    const keys_of_back_pain_doctor_diagnosis_onboarding =
      this.state.student_details?.onboarding_survey?.back_pain_doctor_diagnosis?.split(',');
    const chunked_backpain_doctor_diagnosis = chunk_array(keys_of_back_doctor_diagnosis, 2);
    const client_back_doctor_diagnosis = keys_of_back_pain_doctor_diagnosis_onboarding || [];

    const latest_back_pain_doctor_diagnosis = [];
    const old_back_pain_doctor_diagnosis = [];
    for (const area of client_back_doctor_diagnosis) {
      if (keys_of_back_doctor_diagnosis.indexOf(area) != -1)
        latest_back_pain_doctor_diagnosis.push(area);
      else old_back_pain_doctor_diagnosis.push(area);
    }
    return (
      <div>
        <table>
          <tbody>
            {chunked_backpain_doctor_diagnosis.map((arrays, index) => (
              <tr key={index}>
                {arrays.map((item, new_index) => {
                  const item_index = latest_back_pain_doctor_diagnosis.indexOf(item);
                  return (
                    <td key={new_index}>
                      <div className="profile-yoga-goals">
                        <CustomCheckBox
                          label={convertToSentenceCase(item)}
                          checked={item_index > -1}
                          onClick={() => this.edit_back_pain_doctor_diagnosis(item, item_index)}
                        />
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  render_pain_area = () => {
    const keys_of_back_pain_area = Object.keys(this.props?.config_values?.back_pain_location);
    const keys_of_back_pain_area_onboarding =
      this.state.student_details?.onboarding_survey?.back_pain_location?.split(',');
    const chunked_backpain_area = chunk_array(keys_of_back_pain_area, 2);
    const client_back_pain_area = keys_of_back_pain_area_onboarding || [];

    const latest_back_pain_area = [];
    const old_back_pain_area = [];
    for (const area of client_back_pain_area) {
      if (keys_of_back_pain_area.indexOf(area) != -1) latest_back_pain_area.push(area);
      else old_back_pain_area.push(area);
    }
    return (
      <div>
        <table>
          <tbody>
            {chunked_backpain_area.map((arrays, index) => (
              <tr key={index}>
                {arrays.map((item, new_index) => {
                  const item_index = latest_back_pain_area.indexOf(item);
                  return (
                    <td key={new_index}>
                      <div className="profile-yoga-goals">
                        <CustomCheckBox
                          label={convertToSentenceCase(item)}
                          checked={item_index > -1}
                          onClick={() => this.edit_pain_area(item, item_index)}
                        />
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  render_goals = () => {
    const chunked_goals = chunk_array(this.props.config_values.student_goals, 2);
    const user_goals = this.state.student_details.yoga_goals.split(',');
    const latest_goals = [];
    const old_goals = [];
    for (const goal of user_goals) {
      if (this.props.config_values.student_goals.indexOf(goal) != -1) latest_goals.push(goal);
      else old_goals.push(goal);
    }
    return (
      <div>
        <table>
          <tbody>
            {chunked_goals.map((arrays, index) => (
              <tr key={index}>
                {arrays.map((item, new_index) => {
                  const item_index = latest_goals.indexOf(item);
                  return (
                    <td key={new_index}>
                      <div className="profile-yoga-goals">
                        <CustomCheckBox
                          label={item}
                          checked={item_index > -1}
                          onClick={() => this.edit_goals(item, item_index)}
                        />
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
        <div className="student-old-goals">
          {old_goals.map((goal) => (
            <div className="student-old-goals-div" key={goal}>
              <span>{goal}</span>
              <img
                src="https://images.myyogateacher.com/icons/ic_cross@2x.png"
                alt="x"
                onClick={() => this.edit_goals(goal)}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  render_student_preferred_time = () => {
    try {
      const timeslots = chunk_array(student_timeslots, 3);
      const student_preferred_timeslots = JSON.parse(
        this.state.student_details.preferred_practice_timebands
      );
      return (
        <table>
          <tbody>
            {timeslots.map((arrays, index) => (
              <tr key={index}>
                {arrays.map((item, new_index) => {
                  const item_index = student_preferred_timeslots.indexOf(item);
                  return (
                    <td key={new_index}>
                      <div className="profile-yoga-goals">
                        <CustomCheckBox
                          label={mapped_student_timeslots[`${item}`]}
                          checked={item_index > -1}
                          onClick={() => this.edit_preferred_timeslots(item, item_index)}
                        />
                      </div>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      );
    } catch (e) {
      return null;
    }
  };

  edit_yoga_types = (item, item_index) => {
    const array = this.state.student_details.yoga_types_practiced.split(',');
    if (item_index < 0) {
      array.push(item);
    } else {
      array.splice(item_index, 1);
    }
    this.setState({
      student_details: {
        ...this.state.student_details,
        yoga_types_practiced: array.join(',')
      }
    });
  };

  render_yoga_types = () => {
    const chunked_yoga_types = chunk_array(new_yoga_types, 3);
    const yoga_types = this.state.student_details.yoga_types_practiced.split(',');
    return (
      <table>
        <tbody>
          {chunked_yoga_types.map((arrays, index) => (
            <tr key={index}>
              {arrays.map((item, new_index) => {
                const item_index = yoga_types.indexOf(item);
                return (
                  <td key={new_index}>
                    <div className="profile-yoga-goals">
                      <CustomCheckBox
                        label={item}
                        checked={item_index > -1}
                        onClick={() => this.edit_yoga_types(item, item_index)}
                      />
                    </div>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  save_profile = () => {
    const details = this.state.student_details;
    const { password, iana_timezone, concierge_entered_ethnicity } = this.state;
    let { phone_cc } = details;
    if (phone_no_codes.findIndex((c) => c.value === phone_cc) === 1) {
      phone_cc = '1';
    }
    const student_details = {
      student_uuid: this.state.student_details.student_uuid,
      first_name: this.state.student_details.first_name,
      middle_name: this.state.student_details.middle_name,
      last_name: this.state.student_details.last_name,
      phone_no: this.state.student_details.phone_no,
      phone_cc,
      yoga_level: this.state.student_details.yoga_level,
      health_history: this.state.student_details.health_history,
      session_price: this.state.student_details.session_price,
      test_user: this.state.student_details.test_user,
      teacher_evaluator: this.state.student_details.teacher_evaluator,
      years_of_experience: this.state.student_details.years_of_experience,
      credits_expiry: `${this.state.student_details.credits_expiry} 23:59`,
      goals: this.state.student_details.yoga_goals,
      yoga_types_practiced: this.state.student_details.yoga_types_practiced,
      // is_banned: this.state.student_details.is_banned,
      allow_booking_outside_us: this.state.student_details.allow_booking_outside_us,
      allow_booking_without_mobile_verification:
        this.state.student_details.allow_booking_without_mobile_verification,
      allow_community_class: this.state.student_details.allow_community_class,
      verified_phone_number: this.state.student_details.verified_phone_number,
      allow_vishesh_teacher: this.state.student_details.allow_vishesh_teacher,
      allow_teacher_replacement: details.allow_teacher_replacement,
      receipt_name: details.receipt_name,
      preferred_practice_timebands: details.preferred_practice_timebands,
      back_pain_location: this.state.student_details.onboarding_survey.back_pain_location,
      back_pain_duration: this.state.student_details.onboarding_survey.back_pain_duration,
      back_pain_trigger: this.state.student_details.onboarding_survey.back_pain_trigger,
      back_pain_doctor_diagnosis:
        this.state.student_details.onboarding_survey.back_pain_doctor_diagnosis,
      back_pain_intensity: this.state.student_details.onboarding_survey.back_pain_intensity,
      height_feet: this.state.student_details.onboarding_survey.height_feet,
      height_inches: this.state.student_details.onboarding_survey.height_inches,
      weight: this.state.student_details.onboarding_survey.weight
    };
    if (iana_timezone !== details.iana_timezone) {
      student_details.iana_timezone = iana_timezone;
    }
    if (password) {
      student_details.password = password;
    }
    if (concierge_entered_ethnicity != details.concierge_entered_ethnicity) {
      student_details.concierge_entered_ethnicity = concierge_entered_ethnicity;
    }
    const url = get_api_url('update_student_data');
    post_api(url, student_details, true)
      .then(() => this.props.edit_profile_success())
      .catch(() => console.log('save error'));
  };

  render() {
    if (this.state.loading) {
      return <CustomLoading />;
    }
    const { password, iana_timezone, timezones, concierge_entered_ethnicity } = this.state;
    const details = this.state.student_details;
    return (
      <div
        className={`common-page-container ${details.client_domain === 'backpain' ? 'bg-mytBisque' : ''}`}
      >
        <div className="common-page-header">
          <div className="profile-go-back" onClick={this.props.close_profile}>
            <img
              src="https://s3.amazonaws.com/images.myyogateacher.com/website/home/arrow_back.png"
              alt="back"
            />
            <span>Back</span>
          </div>
          <div className="common-page-title">Edit Profile</div>
        </div>
        <div className="edit-profile-details">
          <div className="edit-profile-profile-pic">
            <img src={details.profile_photo} alt="pic" onError={image_error_replace} />
            {details.email}
            <div style={{ margin: '10px 0' }}>
              <button onClick={this.save_profile}>Save Profile</button>
            </div>
          </div>
          <div className="edit-profile-personal-details">
            <div className="edit-profile-single-detail">
              <CustomInput
                label="First name"
                value={details.first_name}
                name="first_name"
                onChange={this.handle_change}
              />
            </div>
            <div className="edit-profile-single-detail">
              <CustomInput
                label="Middle name"
                value={details.middle_name}
                name="middle_name"
                onChange={this.handle_change}
              />
            </div>
            <div className="edit-profile-single-detail">
              <CustomInput
                label="Last name"
                value={details.last_name}
                name="last_name"
                onChange={this.handle_change}
              />
            </div>
            <div className="edit-profile-single-detail">
              <CustomInput
                label="Receipt name"
                value={details.receipt_name || ''}
                name="receipt_name"
                onChange={this.handle_change}
              />
            </div>
            <div className="edit-profile-single-detail">
              <CustomInput
                label="Password"
                value={password}
                name="password"
                onChange={this.on_common_change}
              />
              <div className="edit-profile-single-detail">
                <TimezoneDropdown
                  label="Timezone"
                  timezones={timezones}
                  value={iana_timezone}
                  onChange={this.on_common_change}
                  name="iana_timezone"
                />
              </div>
              <div className="edit-profile-single-detail">
                <div className="edit-profile-textarea-label">
                  ML Ethnicity: <b>{details.ml_ethnicity}</b>
                </div>
              </div>
              <div className="edit-profile-single-detail">
                <CustomSingleSelect
                  label="Concierge Entered Ethnicity"
                  options={EthnicityOptions}
                  onChange={this.on_common_change}
                  value={concierge_entered_ethnicity}
                  name="concierge_entered_ethnicity"
                />
              </div>
            </div>
            <div className="edit-profile-yoga">
              <div className="edit-profile-single-detail">
                <CustomCheckBox
                  label="Test User"
                  checked={details.test_user === 1}
                  onClick={() => this.on_box_click('test_user', details.test_user)}
                />
              </div>
              <div className="edit-profile-single-detail">
                <CustomCheckBox
                  label="Teacher Evaluator"
                  checked={details.teacher_evaluator === 1}
                  onClick={() => this.on_box_click('teacher_evaluator', details.teacher_evaluator)}
                />
              </div>
              <div className="edit-profile-single-detail">
                <CustomCheckBox
                  label="Allow booking outside US"
                  checked={details.allow_booking_outside_us === 1}
                  onClick={() =>
                    this.on_box_click('allow_booking_outside_us', details.allow_booking_outside_us)
                  }
                />
              </div>
              <div className="edit-profile-single-detail">
                <CustomCheckBox
                  label="Allow booking group class without verified phone number"
                  checked={details.allow_booking_without_mobile_verification === 1}
                  onClick={() =>
                    this.on_box_click(
                      'allow_booking_without_mobile_verification',
                      details.allow_booking_without_mobile_verification
                    )
                  }
                />
              </div>
              <div className="edit-profile-single-detail">
                <CustomCheckBox
                  label="Access to vishesh teacher"
                  checked={details.allow_vishesh_teacher === 1}
                  onClick={() =>
                    this.on_box_click('allow_vishesh_teacher', details.allow_vishesh_teacher)
                  }
                />
              </div>
              <div className="edit-profile-single-detail">
                <CustomCheckBox
                  label="Allow teacher replacement"
                  checked={details.allow_teacher_replacement === 1}
                  onClick={() =>
                    this.on_box_click(
                      'allow_teacher_replacement',
                      details.allow_teacher_replacement
                    )
                  }
                />
              </div>

              <div className="edit-profile-single-detail">
                <CustomCheckBox
                  label="Can create community class"
                  checked={details.allow_community_class === 1}
                  onClick={() =>
                    this.on_box_click('allow_community_class', details.allow_community_class)
                  }
                />
              </div>
              <div className="edit-profile-single-detail">
                <CustomCheckBox
                  label="Number Verified"
                  checked={details.verified_phone_number === 1}
                  onClick={() =>
                    this.on_box_click('verified_phone_number', details.verified_phone_number)
                  }
                />
              </div>
            </div>
            <div className="edit-profile-single-detail">
              <CustomSingleSelect
                label="Phone Country"
                defaultOption="Select country code"
                options={phone_no_codes}
                onChange={this.handle_change}
                value={details.phone_cc}
                name="phone_cc"
                defaultOptionDisabled={false}
              />
            </div>
            <div className="edit-profile-single-detail">
              <CustomInput
                label="Phone Number"
                value={details.phone_no}
                name="phone_no"
                onChange={this.handle_change}
              />
            </div>
            {/* <div className="edit-profile-single-detail">
							<CustomSingleSelect
								label="User Banned"
								options={ban_options}
								onChange={this.handle_change}
								value={details.is_banned}
								name="is_banned"
							/>
						</div> */}
            <div className="edit-profile-textarea">
              <div className="edit-profile-textarea-label">
                Yoga types teaching:{this.render_yoga_types()}
              </div>
            </div>
            <div className="edit-profile-single-detail">
              <CustomDatePicker
                label="Credits Expiry"
                value={details.credits_expiry}
                handleChange={(val) => this.handle_date_change('credits_expiry', val)}
                minDate={moment().format('YYYY-MM-DD')}
              />
            </div>
            <div className="edit-profile-single-detail">
              {details.client_domain === 'backpain' ? (
                <CustomSingleSelect
                  label="Back Pain Duration"
                  options={format_to_label_value(this.props?.config_values?.back_pain_duration)}
                  onChange={this.handle_Pain_duration_change}
                  value={details.onboarding_survey.back_pain_duration}
                  name="back_pain_duration"
                />
              ) : (
                <CustomSingleSelect
                  label="Yoga Level"
                  options={new_yoga_levels}
                  onChange={this.handle_change}
                  value={details.yoga_level.back_pain_duration}
                  name="yoga_level"
                />
              )}
            </div>
            {details.client_domain === 'backpain' ? (
              <div className="edit-profile-single-detail">
                <CustomSingleSelect
                  label="Back Pain Intensity"
                  options={back_pain_intensity}
                  onChange={this.handle_back_pain_intensity_change}
                  value={details.onboarding_survey.back_pain_intensity}
                  name="back_pain_intensity"
                />
              </div>
            ) : (
              <></>
            )}
            {details.client_domain === 'backpain' ? (
              <>
                <div className="edit-profile-single-detail">
                  <CustomSingleSelect
                    label="Client Height In ft"
                    options={client_height_in_ft}
                    onChange={this.handle_client_height_in_ft_change}
                    value={details.onboarding_survey.height_feet}
                    name="client_height_in_ft"
                  />
                </div>
                <div className="edit-profile-single-detail">
                  <CustomSingleSelect
                    label="Client Height In Inch"
                    options={client_height_in_inc}
                    onChange={this.handle_client_height_in_inc_change}
                    value={details.onboarding_survey.height_inches}
                    name="client_height_in_inc"
                  />
                </div>
                <div className="edit-profile-single-detail">
                  <CustomInput
                    label="Client Weight In Ib or Pounds"
                    onChange={this.handle_client_weight_change}
                    value={details.onboarding_survey.weight}
                    name="client_weight"
                  />
                </div>
              </>
            ) : (
              <></>
            )}
            <div className="edit-profile-textarea">
              <div className="edit-profile-textarea-label">Health History:</div>
              <textarea
                onChange={this.handle_change}
                value={details.health_history}
                name="health_history"
              />
            </div>
            <div className="edit-profile-textarea">
              <div className="edit-profile-textarea-label">Reason for Back Pain Triggerd:</div>
              <textarea
                onChange={this.handle_back_pain_trigger}
                value={details.onboarding_survey.back_pain_trigger}
                name="back_pain_trigger"
              />
            </div>
            <div className="edit-profile-single-detail">
              <CustomInput
                label="Session Price"
                value={details.session_price}
                name="session_price"
                onChange={this.handle_number_change}
                type="number"
              />
            </div>
            <div className="edit-profile-textarea">
              {details.client_domain === 'backpain' ? (
                <div className="edit-profile-textarea-label">
                  Back Pain Area:{this.render_pain_area()}
                </div>
              ) : (
                <div className="edit-profile-textarea-label">Yoga Goals:{this.render_goals()}</div>
              )}
            </div>
            <div className="edit-profile-textarea">
              {details.client_domain === 'backpain' ? (
                <div className="edit-profile-textarea-label">
                  Back Pain Doctor Diagnosis: {this.render_back_pain_doctor_diagnosis()}
                </div>
              ) : null}
            </div>
            <div className="edit-profile-textarea">
              <div className="edit-profile-textarea-label">
                Student Preferred Time:{this.render_student_preferred_time()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
